import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Banner from "../components/banner"
import { Icon } from 'semantic-ui-react';
// import { useStaticQuery, graphql, Link } from "gatsby";
// import policy from 'raw-loader!../assets/documents/greenpolicy.md'

const dpoEmail = <a href={`mailto:dpo@jinmac.org?subject=Privacy`}>dpo@jinmac.org</a>;

const PrivacyPolicyPage = (params) => {
  return (
    <Layout {...params}>
			<div className="privacy page">

				<SEO title={`Privacy Policy`} />
				<section className="content description" id="privacypolicy">
					{/* <Image src={shieldlockicon} /> */}
					<div className="mark">
						
						<p className="meta">Last modified: 29 September 2020.</p>
						<h2><Icon name="user shield"/> Privacy Policy</h2>
						<p>Jinmac Pte Ltd is committed to protecting and respecting your privacy. This Privacy Policy sets out how we collect and process personal information about you when you visit our websites, when you use our products and services (our “Services”), or when you otherwise do business or make contact with us.</p>
						<p>Please read this policy carefully to understand how we handle and treat your personal information.</p>
						
						<h2>How We Collect Your Personal Data</h2>

						<p>The PDPA defines personal data as “data, whether true or not, about an individual who can be identified a) from that data; or b) from that data and other information to which the organisation has or is likely to have access.”</p>

						<p>We collect your personal data in the following ways:</p>

						<ul>
							<li>When you provide your personal contact information to us when making enquiries, giving feedback or lodging complaints</li>

							<li>When you provide your personal particulars to us for the sale/lease transaction of properties developed and marketed by us</li>

							<li>When you provide your personal contact information in the visitors’ or contractors’ log books in entering our/our clients’ premises</li>

							<li>When you submit your CV and job application form to us in response to our recruitment publicity and advertisements in newspapers and websites, or at roadshows or job fairs</li>
						</ul>

						<h2>Types of Personal Data We Collect About You</h2>

						<p>The types of personal data we collect about you may include:</p>

						<ul>
							<li>Personal contact information (Name, Address, Phone No., Email address)</li>

							<li>Educational and professional qualifications – for job application</li>

						</ul>

						<h2>What we do with the information</h2>

						<p>The personal information we collect from you may be used in one of the following ways:</p>

						<ul>
							<li>To follow up with your inquiries and requests</li>
							<li>To provide you with information and access to resources that you have requested from us</li>
							<li>For general business purposes, including to improve customer service (your information helps us to more effectively respond to your customer service requests and support needs), to help us improve the content and functionality of our Services, to better understand our users, to protect against wrongdoing, to enforce our Terms of Service, and to generally manage our business</li>
							<li>To process transactions and to provide Services to our customers and end-users</li>
							<li>To administer a contest, promotion, survey, or other site features</li>
							<li>Provide services for property and facilities management</li>
							<li>Assess competency and fitness of workers in compliance with work safety regulations</li>
							<li>Record your entry to our/our client’s premises for security purposes</li>
							<li>For recruitment purposes, where you apply for a job with us</li>

						</ul>

						<h2>Who We Disclose Your Personal Data To</h2>

						<p>We disclose some of the personal data we have collected about you to the following parties or organisations outside Jinmac:</p>

						<ul>
							<li>Government agencies (e.g. MOM for man year)</li>
							<li>Registration and licensing authorities (e.g. URA / BCA)</li>
							<li>External advisors (e.g. auditors, architects, lawyers)</li>
							<li>Trusted third parties to perform functions and provide services to us, including cloud hosting services, off-site backups, email service providers, and customer support providers. We will only share your personal information with third parties to the extent necessary to perform these functions, in accordance with the purposes set out in this Privacy Policy and applicable laws.</li>

						</ul>

						<p>We may share the information with others on an aggregate basis.</p>
						<p>Personally identifiable information or business information will not be shared with parties except as required by law.</p>
						<p>We may also use the information we collect to notify you about important changes to our website, new services, and special offers we think you will find valuable.</p>

						<p>You may notify us at any time if you do not wish to receive these offers by emailing us at {dpoEmail}.</p>

						<p>We do not sell or trade your personal information to third parties.</p>

						<h2>Your Rights</h2>

						<p>You have the right to view, amend, or delete the personal information that we hold about you.</p>

						<p>We will provide the information free of charge and within one month, except in the event that the request is unfounded, excessive or repetitive, in which case we reserve the right to charge a proportionate administration fee or refuse.</p>

						<p>If any of the information we hold on you is inaccurate or incomplete, you make request to us to rectify the error.</p>

						<p>You may request the deletion of any information we hold on you at any time except those that we are legally required to maintain.</p>

						<p>To exercise any of the above mentioned rights, contact our data protection officer at {dpoEmail} with sufficient information for us to verify your identity.</p>

						<h2>Third Party Links</h2>

						<p>Occasionally, at our discretion, we may include or offer third party products or services on our website. If you access other websites using the links provided, the operators of these websites may collect information from you that will be used by them in accordance with their privacy policies. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>

						<h2>Security</h2>

						<p>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.</p>

						<p>Wherever we collect sensitive information, that information is encrypted and transmitted to us in a secure way.</p>
						<p>You can verify this by looking for a <Icon name="protect"/> icon in the address bar and looking for “https” at the beginning of the address of the web page.</p>

						<p>While we use encryption to protect sensitive information transmitted online, we also protect your information offline.</p>
						<p>Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information.</p>
						<p>The computers/servers in which we store personally identifiable information are kept in a secure environment.</p>

						<h2>Changes to this Privacy Policy</h2>

						<p>If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date.</p>
					</div>
				</section>
			</div>
    </Layout>
  )
}

export default PrivacyPolicyPage;